<template>
  <layout-admin-dashboard-view :back-route="{ name: 'admin-dashboard' }">
    <template #title>
      {{ translateTitleCase("entity.crud.entityListTitle") }}
    </template>

    <template #mainContent>
      <spinner v-if="asyncOps.asyncStatus.loading"></spinner>
      <block v-else>
        <card class="">
          <div class="flex flex-wrap gap-l flex-stretch">
            <router-link
              v-for="entity of entitiesWithEntityApi"
              :key="entity.slug"
              v-slot="{ navigate, href, route }"
              :to="{ name: 'entity-list', params: { entityType: entity.slug } }"
              custom
              :entity="entity">
              <panel
                theme="inverse"
                class="card--hover"
                :full-width="false"
                :title="entity.namePlural"
                style="min-width: 350px"
                @click="navigate">
                <p>{{ safeTranslate(entity.description) }}</p>
              </panel>
            </router-link>
          </div>
        </card>
      </block>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import { useStore } from "vuex";

export default {
  setup: function (props) {
    let { asyncOps, asyncOpsReady } = asyncOperations(props, useStore());

    return { asyncOps, asyncOpsReady };
  },
  data: function () {
    return {
      entityList: {},
      asyncData: {
        // simple version: just route for the data
        entityList: "entity", // should also work with just string
        /*
         // advanced version:
         test2: {
         // api url
         target : 'sample',
         //target : 'http://localhost:3001/api/sample',
         // data for the request. use computed() composition to make reactive
         data : computed(() => this.dataForTest2),
         // options for the request: method, requestAdapter, responseAdapter
         options : {
         method: 'post',
         //  requestUrlFull : true // will not append prefix to target, and instead use the full url.
         //    responseRaw: true, // allows to get the full response from server
         //       requestAdapter : 'default', // request adapter
         //       responseAdapter : 'raw', // response adapter
         },

         //  static request data exmplae
         //      data : {
         //     'testDataKey' : 'testDataValue'
         //  },

         },
         */
      },
    };
  },
  computed: {
    entitiesWithEntityApi() {
      let result = {};

      for (const [key, val] of Object.entries(this.entityList)) {
        if (val && val.usesEntityApi) {
          result[key] = val;
        }
      }

      return result;
    },
  },
};
</script>

<style scoped lang="scss">
.entity-link {
  cursor: pointer;
}
</style>
